import { useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';

export const useComponentWillMount = (func:()=>any) => {
  const willMount = useRef(true);

  if (willMount.current) {
    func();
  }

  willMount.current = false;
};

export const useComponentDidMount = (func:()=>any) => useEffect(func, []);

// This hook is extension of useQuery in case we need
// to add custom caching in future for page schema
export const useSchema = (query:any, options:any) => useQuery(query, {
  ...options,
  fetchPolicy: 'cache-and-network',
});
