import styled from 'styled-components';
import { GlobalWrapper } from '../../components/global.style';

export const BrowseContainer = styled.div`
  background: ${({ theme: { shades } }:any) => shades[1]};
  height: 100%;
  overflow: ${({ $disableScroll = false }:any) => ($disableScroll ? 'hidden' : 'auto')};
  ${GlobalWrapper}{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
`;

export const CategoriesContainer = styled.div`
  ${({ theme: { shades } }: any) => `
    display: block;
    margin: 0.6rem 0;
    padding: 1rem 0;
    background-color: ${shades[0]};
  `}
`;

export default BrowseContainer;
