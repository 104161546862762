import getApolloClient from './getApollo';
import ADD_FAVOURITE_CAMPAIGN from './ADD_FAVOURITE_CAMPAIGN.graphql';

export const addFavouriteCampaign = async (campaignId: string) => {
  const client = await getApolloClient();
  await client.mutate({
    mutation: ADD_FAVOURITE_CAMPAIGN,
    variables: {
      campaignId,
    },
  });

  client.reFetchObservableQueries();
};

export default addFavouriteCampaign;
