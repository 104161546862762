import React, { useState, useEffect, useContext } from 'react';

import { useRouter } from 'next/router';
import { Auth } from 'aws-amplify';
import { BrowseContainer } from './index/browse.style';
import SearchBar from '../components/withData/search';
import Layout from '../components/withData/layout';
import Browse from '../components/withData/browse';
import { withApollo } from '../helpers/apollo';
import { useSchema } from '../helpers/hooks';

import GET_SCHEMA from './index/GET_SCHEMA.graphql';
import tagManager from '../helpers/tagManager';
import { Context, REFERRER_URL } from '../helpers/context';
import { ROUTE, SOCIAL_DATA_STACK, FOR_SOCIAL_FAVOUITE } from '../constants';
import addFavouriteCampaign from '../helpers/doFavouriteCharity';
import social from '../helpers/socialStack';
import { anonymous, getReferrer } from '../helpers/user';
import { PageLoader } from '../components/ui/loading';
import centreUPF from '../helpers/centreUPF';

const oneHrRefresh = ((60000) * 10) * 3;
let timeOut = 0;

export const BrowseCharity: React.FunctionComponent<any> = () => {
  const router = useRouter();
  const { context: { auth }, updateContext, login } = useContext(Context);
  const [state, setState] = useState({
    isSearching: false,
    isformSocial: false,
    pagesSectionState: {},
  });

  const {
    data, error, loading, fetchMore,
  } = useSchema(GET_SCHEMA, {
    variables: {
      pageId: 'BC0',
    },
  });

  const getMediaSocialSigned = async () => {
    const socialDataStack = await social.getStack(SOCIAL_DATA_STACK);
    const socialFavString = await social.getStack(FOR_SOCIAL_FAVOUITE);
    if (socialDataStack) {
      const stack = JSON.parse(socialDataStack);
      const {
        amountValues, charityId,
      } = stack;
      updateContext({
        page: 'donate',
        data: {
          amount: amountValues.amount,
          campaignId: charityId,
          isGiftAid: amountValues.isGiftAid,
        },
      });
      await social.removeStack(SOCIAL_DATA_STACK);
      await social.removeStack(FOR_SOCIAL_FAVOUITE);
      const upfRI = centreUPF(auth);
      router.replace(`${ROUTE.PAYMENT_DETAILS}${upfRI}`);
    } else if (socialFavString) {
      const favData = JSON.parse(socialFavString);
      addFavouriteCampaign(favData.id);
      await social.removeStack(SOCIAL_DATA_STACK);
      await social.removeStack(FOR_SOCIAL_FAVOUITE);
    }
  };

  const createAnonymousUser = async () => {
    try {
      const user = await Auth.currentUserInfo();
      if (!user) {
        const result = await Auth.signIn('anonymousUser');
      
      }
    } catch (error) {
      console.log('Error signing in:', error);
    }
  };
    
  const startReloadCounter = async () => {
    clearTimeout(timeOut);
    try {
      console.log('Refresh...');
      const dataFetched = await fetchMore({
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          // console.log('prev', prev);
          // console.log('fetchMoreResult', fetchMoreResult);
          if (!fetchMoreResult) return prev;
          return { ...prev, ...fetchMoreResult };
        },
      });

      const { data: { getPageSections } } = dataFetched;
      setState((prevState: any) => ({ ...prevState, pagesSectionState: getPageSections }));
      setTimeout(() => {
        startReloadCounter();
      }, oneHrRefresh);
    } catch {
      // console.log('DESTROY FETCH_MORE');
    }
  };

  const getReferrerValue = async () => {
    const referrerObj = await localStorage.getItem(REFERRER_URL);
    if (!referrerObj || referrerObj === '') {
      localStorage.setItem(REFERRER_URL, getReferrer());
    } else if (referrerObj.indexOf('wegive.tech') === -1 || referrerObj.indexOf('ngrok.io') === -1) {
      localStorage.setItem(REFERRER_URL, getReferrer());
    }
  };

  useEffect(() => {
    if (!data) {
      createAnonymousUser();
    } else {
      const { isValidUser } = auth || {};
      if (error) {
        createAnonymousUser();
      } else if (isValidUser) {
        getMediaSocialSigned();
      }
    }

    if (!error) {
      const { getPageSections } = data || {};
      if (getPageSections) {
        setState((prevState: any) => ({ ...prevState, pagesSectionState: getPageSections }));
      }
    }
  }, [auth, error, data]);

  useEffect(() => {
    tagManager.dataLayer('/home (BC0)');
    getReferrerValue();
    setTimeout(() => {
      startReloadCounter();
    }, oneHrRefresh);
  }, []);

  const { pagesSectionState: getPageSections = {} } = state;
  let sections = [];
  // const { getPageSections = {} } = data || {};
  if (getPageSections) {
    // @ts-ignore
    sections = getPageSections.sections;
  }

  return (
    <Layout title="Browse Charities | WeGive" header="Browse Charities">
      {loading && <PageLoader />}
      <BrowseContainer $disableScroll={state.isSearching}>
        <SearchBar
          backgroundProps={{
            style: {
              top: '100px',
              height: 'calc(100% - 110px)',
              padding: 0,
            },
          }}
          onSearching={(isSearching: boolean) => {
            if (isSearching !== state.isSearching) {
              setState(((prevState) => ({ ...prevState, isSearching })));
            }
          }}
        />
        {data && !error ? <Browse schema={sections} /> : null}
      </BrowseContainer>
    </Layout>
  );
};

export default withApollo()(BrowseCharity);
